import initFormToken from 'utils/form-token';
import 'utils/ajax';
import 'utils/cookie';
import 'utils/recaptcha';
import 'utils/translation';
import 'utils/util_static';
import 'utils/util';
import 'hs-events';
initFormToken();

